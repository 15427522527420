import React from "react"
import feather from "feather-icons"

const Icon = (props) => {
	let style = props.style;
	if(props.stroke) {
		style['stroke-width'] = props.stroke;
	}
	if(props.fill) {
		style['fill'] = props.fill;
	}
	return (
		<span { ...props } dangerouslySetInnerHTML={{ __html: feather.icons[ props.icon ].toSvg(style) }} />
	)
}

export default Icon;