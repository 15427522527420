import React from 'react'
import { Link } from 'gatsby'
import Icon from './icon';

const Footer = () => (
  <footer className='cf bg-near-white pv4 bt b--black-05'>
    <div className='w-100 w-80-ns center tl ph4 ph0-ns'>
      <p className='roboto near-black'><Icon icon='mail' className='v-btm black-50 pr3 ' style={{ width: 16, height: 16 }} />hello@spacecitycups.com</p>
      <p className='roboto black-80'><Icon icon='phone' className='v-btm black-50 pr3 ' style={{ width: 16, height: 16 }} />832.919.7610</p>
      <p className='roboto black-80'><Icon icon='clock' className='v-btm black-50 pr3 ' style={{ width: 16, height: 16 }} />M&ndash;F 9&ndash;5</p>
    </div>
  </footer>
)

export default Footer
