import React from 'react'
import { Link } from 'gatsby'
import logo from '../static/assets/scc_logo.png'

const Header = ({ siteTitle }) => (
  <header className='cf'>
    <div className='w-100 w-50-l ph3 ph0-ns fl v-mid'>
      <h1 className='barlow fw4 ttu f2 ma0 pt3 ph0 pb2 near-black tc tl-l'>
        Space City Cups
        <img className='dn mw2 pl3 v-btm' src={ logo } />
      </h1>
      <p className='dn silver i f6 ma0 pa0'>Houston, we have a product.</p>
    </div>
    <div className='w-100 w-50-l ph3 ph0-ns fl v-mid tc tr-l pt3 mt1'>
      <ol className='ph0 ma0 pv2 pv1-ns list'>
        <li className='dib pr2-ns mr3 v-mid mt2 mb3 mv2-ns'><Link activeClassName='bg-black-05 pa2 ph3' className='black-80 pa2 ph3 ttu tracked poppins fw4 f6 f5-l f4-xl no-underline dim' to="/">Home</Link></li>
        <li className='dib pr2-ns mr3 v-mid mt2 mb3 mv2-ns'><Link activeClassName='bg-black-05 pa2 ph3' className='black-80 pa2 ph3 ttu tracked poppins fw4 f6 f5-l f4-xl no-underline dim' to="/custom-engraving/">Custom Engraving</Link></li>
        <li className='dib pr2-ns mr3 v-mid mt2 mv2-ns'><Link activeClassName='bg-black-05 pa2 ph3' className='black-80 pa2 ph3 ttu tracked poppins fw4 f6 f5-l f4-xl no-underline dim' to="/faq/">FAQ</Link></li>
        <li className='dib v-mid mt2 mv2-ns'><Link activeClassName='bg-black-05 pt2 pb2 pl2 pl3' className='black-80 pa2 ph3 ttu tracked poppins fw4 f6 f5-l f4-xl no-underline dim' to="/contact/">Contact</Link></li>
      </ol>
    </div>
  </header>
)

export default Header
